<template>
    <CCard id="printArea">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid"/> {{caption}}
        </slot>
      </CCardHeader>
      <CCardBody>
          <List ref="list" 
              :fields="fields"
              :items.sync=auditLogList
              :loading.sync=loading
              showSearch
              :comboboxData=comboboxData
              sorter      
              :defaultSort=defaultSort
              showExcelBtn
              itemsPerPageSelect
              :itemsPerPage=itemPerPage
              showDatePicker
              :defaultSearchDate=searchDate
              :excel=excel
              showPrintBtn
          >      
          <template #no="{index}">
              <td>
                {{totalCnt - ((pageNum -1) * itemPerPage +index)}}
              </td>
          </template>
          <!--아이템별 버튼 재정의-->     
          <template #show_buttons="{item, index}">
            <td class="py-2" >
              <CButton
                size="sm" 
                square
                v-c-tooltip="'상세보기'"
                @click="detailItem(item, index)"
                class="btn btn-primary"
                >
                <CIcon
                  :content="$options.icons.cilMagnifyingGlass"
                />
              </CButton>
            </td>
          </template>   
          </List>
          <page-link ref="page"
              :pageLinkCount=pagePerLink
              :listRowCount=itemPerPage
          />
          <AuditLogInfoModal v-if="isOpenModifyAppModal" :auditLogKey="auditLogKey" @close-modal="isOpenModifyAppModal=false"/>
        </CCardBody>
    </CCard>
</template>


<script>
import List from "@/views/common/List.vue";
import PageLink from "@/views/common/PageLink.vue";
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import { cilMagnifyingGlass} from '@coreui/icons'
import AuditLogInfoModal from './AuditLogInfoModal'
export default {
    name: 'auditLogList',
    icons: { cilMagnifyingGlass},
    components: {
        List,
        PageLink,
        CIcon,
        AuditLogInfoModal
    },
    computed: {
      // 목록에 들어갈 데이터
      auditLogList(){ 
        return this.$store.state.auditLog.auditLogList || []
      },
      searchDate(){
          var beforeDay = new Date()
          beforeDay.setDate(beforeDay.getDate()-7)
          return [beforeDay, new Date()]
      },   
      totalCnt(){
        return this.$store.state.auditLog.totalCnt
      }
  },
  // 로컬변수생성
  data() { 
		return{
      searchType:"regId", // 콤보박스 디폴트 값(하기 comboboxData의 value로 설정)
      searchText:"",
      // 검색조건 설정
      comboboxData:[{label: '항목', value: 'logType', codeYn: true, subCode:'logEvent'}
                    ,{label: '계정', value:'regId'}],
      // 그리드 컬럼 설정
      fields:[
          { key: 'no', label:'No', _style: { width: '2%'} },
          { key: 'regId', label:'계정', _style: { width: '10%'} },
          { key: 'regIp', label:'IP', _style: { width: '10%'} },
          { key: 'logResult', label:'결과', _style: { width: '10%'}, badgeYn: true, codeYn: true },
          { key: 'logTarget', label:'타겟', _style: { width: '10%'}},
          { key: 'logType', label:'항목', _style: { width: '20%'}, codeYn: true },
          { key: 'logEvent', label:'이벤트', _style: { width: '10%'}, codeYn: true },
          { key: 'regDate', label:'등록일', _style: { width: '10%'} },
          { 
            key: 'show_buttons', 
            label: '', 
            _style: { width: '13%'}, 
            sorter: false,
          },
      ],
      pagePerLink: 5, // 한번에 보여줄 페이지번호 수
      defaultSort: "-regDate", // 기본 정렬 설정.
      pageNum: 1, // 페이지번호
      itemPerPage:8,  // 한페이지당 보여줄 데이터수
      loading:false,
      alert:{ 
        noItem:"선택된 아이템이 없습니다.",
      },
      isOpenModifyAppModal: false,
      excel: {
        excelType : '1',  // 엑셀타입 (0: 검증로그, 1: 감사로그, 2: 앱 목록, 3: 앱정책감사로그, 4: 권한그룹 목록, 5: 관리자 목록)
        excelFileNm : "감사 로그"
      },
      auditLogKey:'',
    }
  },
  // 파라미터
  props: {
    caption: {
      default: '감사 로그'
    },
  },
  mounted(){
    this.searchList() 
  },
  methods: {
    // 리스트조회
    async searchList(){     
      let payload = this.$refs.list.getParams()
      this.loading=true 
      try {
        await this.$store.dispatch('auditLog/getAuditLogList', payload)
        this.$refs.page.reloadPagination(this.totalCnt)
        this.loading=false
      } catch(error){
        this.$response(error)
        this.loading=false
      }
    },
    async detailItem(item){
        this.auditLogKey = await item.auditLogKey
        this.isOpenModifyAppModal=true
    },
  }
}
</script>