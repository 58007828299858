<template>
  <div class="wrapper">
    <CModal
    
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="lg"
      color="secondary"
    >
    <CCard>
        <CCardBody>
        <CRow>
            <CCol lg="12">     
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>계정</Strong>
                    </CCol>
                    <CCol>
                    {{regId}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>IP</Strong>
                    </CCol>
                    <CCol>
                    {{regIp}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>등록일</Strong>
                    </CCol>
                    <CCol>
                    {{$convertDate(regDate)}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>타겟</Strong>
                    </CCol>
                    <CCol>
                    {{logTarget}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>항목</Strong>
                    </CCol>
                    <CCol>
                    {{$renderer('logType', 0, logType)}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>이벤트</Strong>
                    </CCol>
                    <CCol>
                    {{$renderer('logEvent', 0, logEvent)}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>결과</Strong>
                    </CCol>
                    <CCol>                    
                        <h5><CBadge :color="$renderer('logResult', 1, logResult)">
                            {{$renderer('logResult', 0, logResult)}}
                        </CBadge></h5>
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="2">
                      <Strong>상세내용</Strong>
                    </CCol>
                    <CCol>
                    <div v-html="logDesc"></div>
                    </CCol>
                </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <template #header >
        <h6 class="modal-title">감사 로그 상세보기</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
        </template>
        <template #footer>
        <CButton class="btn btn-primary" @click="$emit('close-modal')">닫기</CButton>
    </template>
    </CModal>
  </div>

</template>

<script>
export default {
    name: 'VerifyInfoModal',
    data () {
        return {
            isModalOpen:true,
            regId:'',
            regIp:'',
            regDate:'',
            logTarget:'',
            logType:'',
            logEvent:'',
            logResult:'',
            logDesc:'',
        }
    },
    props: {
        auditLogKey: String,
    },
    mounted(){
        this.searchAuditLog() 
    },
    methods: {
        async searchAuditLog(){
          
          this.loading=true 
          try {
              await this.$store.dispatch('auditLog/getAuditLogInfo', this.auditLogKey)
              var auditLogInfo = this.$store.state.auditLog.auditLogInfo
              this.regId = auditLogInfo.regId
              this.regIp = auditLogInfo.regIp
              this.regDate = auditLogInfo.regDate
              this.logTarget = auditLogInfo.logTarget
              this.logType = auditLogInfo.logType
              this.logEvent = auditLogInfo.logEvent
              this.logResult = auditLogInfo.logResult
              this.logDesc = auditLogInfo.logDesc?auditLogInfo.logDesc.split('\n').join('<br />'):'';
              this.loading=false
          } catch(error){
              this.$response(error)
              this.loading=false
          }           
        },
        detailItem(){
            this.isOpenModifyAppModal=true
        },
    }
}
</script>
